import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [inputValue, setInputValue] = useState('');

  const handleAdd = () => {
    axios.post('https://script.google.com/macros/s/AKfycbyjgx09QjtXzYL4cCyx9A9wos7Hb40BbQzQtQx07utaTd9Lk_JnYFAUHXXbWO19PyX9/exec', {
      action: 'add',
      value: inputValue
    })
    .then(response => console.log(response))
    .catch(error => console.error(error));
  };

  const handleDelete = (rowNumber) => {
    axios.post('https://script.google.com/macros/s/AKfycbyjgx09QjtXzYL4cCyx9A9wos7Hb40BbQzQtQx07utaTd9Lk_JnYFAUHXXbWO19PyX9/exec', {
      action: 'delete',
      row: rowNumber
    })
    .then(response => console.log(response))
    .catch(error => console.error(error));
  };

  return (
    <div>
      <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <button onClick={handleAdd}>Add</button>
      <button onClick={() => handleDelete(1)}>Delete Row 1</button>
    </div>
  );
};

export default App;
